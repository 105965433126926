.enhanced-delivery-page {
  .pg_wrapper {
    .column.left {
      .info-content {
        padding-top: 4px;
      }
      #checkout_complete {
        .wrapper {
          #payment-info-panel {
            .cc-header,
            #payment-type-ncc {
              border-top: 1px solid $color-nobel;
            }
            #payment-type-ncc {
              border-bottom: 1px solid $color-nobel;
              .payment-type {
                border-bottom: 1px solid $color-nobel;
              }
            }
            #payment-type-cc,
            #payment-type-ncc {
              .payment-type {
                img {
                  margin-top: 0;
                }
              }
              .group2 {
                .payment-type {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
