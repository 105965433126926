.tooltip-gdpr {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  padding-bottom: 10px;
  a {
    text-decoration: underline;
  }
  .tooltiptext-over-gdpr {
    visibility: hidden;
    width: 100%;
    max-width: 365px;
    background-color: $color-white;
    color: $color-gray-dark;
    border: 1px solid $color-nobel;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
    line-height: 14px;
    overflow-y: scroll;
    height: 80px;
    bottom: 100%;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0%;
      border-width: 5px;
      border-color: transparent transparent $color-gray-dark transparent;
    }
  }
  &:hover .tooltiptext-over-gdpr {
    visibility: visible;
    opacity: 1;
  }
}
