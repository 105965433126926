/* legal popup */
.info-link {
  cursor: pointer;
  margin-bottom: 10px;
  border: 0px;
  display: block;
}

.info-message-txt {
  display: none;
}
/* Info popup styles for privacy policy */
.info-icon {
  cursor: pointer;
  background: black;
  color: white;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: block;
  text-transform: lowercase;
  padding: 1px 0px 0px 9px;
}

.info-msg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10000;
  background-color: white;
  padding: 20px;
  width: 90%;
  border: 1px solid black;
  max-width: 510px;
  word-wrap: break-word;
  &__arrow {
    content: ' ';
    height: 0;
    position: absolute;
    width: 0;
    left: 0px;
    border: 10px solid transparent;
    border-bottom-color: black;
    top: -10px;
    margin-top: -10px;
    &.top-right-arrow {
      left: 478px; /* 500px minus Border 2px and Triangle 20px */
    }
  }
  &__txt {
    position: relative;
    span {
      cursor: pointer;
      color: $color-pink;
      text-decoration: underline;
    }
  }
  &__close {
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 15px;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
  }
}

#registration_short {
  .accept-privacy-info {
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

.site-email-signup__form,
.site-utils__dropdown__content {
  .tooltip {
    display: none;
  }
  .tooltiptext-under {
    display: none;
  }
}

.info-for-left,
.info-right-icon {
  float: left;
}

.info-right-icon {
  margin-left: 10px;
}

a.info-link {
  &[message-in='email-notice'],
  &[message-in='email-terms-text'] {
    color: $color-pink;
    text-decoration: underline;
  }
  &[message-in='sign-in-legal-link'],
  &[message-in='address-terms-text'],
  &[message-in='sms-legal-link'],
  &[message-in='email-ck-legal-link'],
  &[message-in='address-ck-legal-link'],
  &[message-in='appointment-terms-msg'] {
    text-decoration: underline;
  }
}

.gdpr-required-notes {
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.tools-pages {
  &.info-link {
    width: 140px;
    margin: 20px auto;
  }
}

//Waitlist GDPR
@import '../../emea_base/scss/account/_waitlist.scss';
/* GDPR Live Chat Style */
.section-chatprivacypolicy {
  .site-header,
  .site-footer {
    display: none;
  }
}

//One Trust Implementation CSS Changes
.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    @include breakpoint($medium-up) {
      .optanon-alert-box {
        &-body {
          margin: 0 40px;
        }
        &-button-container {
          position: relative;
          margin: 5px;
        }
      }
    }
    .optanon-alert-box-button-middle {
      .accept-cookies-button,
      .cookie-settings-button {
        &:active,
        &:hover {
          background: none;
        }
      }
    }
  }
  .optanon-alert-box-bottom-top {
    .optanon-alert-box-corner-close {
      @include breakpoint($medium-up) {
        top: auto;
        bottom: 22px;
        .banner-close-button {
          &:hover {
            background: url('/media/export/images/btns/ot-close-button.svg') !important;
          }
        }
      }
    }
  }
}

.optanon-status-editable,
.optanon-status-on {
  input[type='checkbox'] {
    & ~ label {
      &:before {
        display: none;
      }
    }
  }
}

.cookie-settings-ot {
  margin-bottom: 20px;
  .optanon-show-settings-popup-wrapper {
    margin: 0 auto;
    height: 35px;
    max-width: 1024px;
  }
}
