.tealium {
  display: none !important;
}
$enable_cookie-img: false !default;
/* NOTIFICATIONS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */
.content {
  #bt_notification {
    .bt-content {
      @include swap_direction(padding, 20px 25px 20px 20px);
      position: relative;
      background-color: $color-white;
      z-index: 1001;
      font-size: 12px;
      line-height: 16px;
      transition: all 5s;
      -webkit-transition: all 5s; /* Safari */
      color: $color-matterhorn-shade;
      border: 1px solid $color-light-gray;
      .bt-close-link {
        position: absolute;
        top: 10px;
        #{$rdirection}: 10px;
        display: block;
        width: 15px;
        height: 15px;
        background: url('/media/export/images/cookies/close_x.png') 50% 50% no-repeat;
        text-indent: -9999em;
      }
      a {
        text-decoration: none !important;
        border: 0;
        width: 100%;
        &.site-utils__dropdown__close {
          width: 19px;
          top: -22px;
          #{$rdirection}: 7px;
        }
      }
      .bt-find-more {
        line-height: 2em;
        font-size: 14px;
        border-bottom: 1px solid $color-pink;
      }
      .bt-pp-section {
        background: $color-dark-red;
        padding: 10px;
        border: 1px solid $color-black;
        color: $color-white;
        display: none;
      }
      .bt-cookie-section {
        padding: 10px 0;
        font-family: $roboto-text;
        background: $color-white;
        color: $color-black;
        h2 {
          color: $color-black;
        }
        h6 {
          border: 0;
          font-size: 20px;
          margin-bottom: 10px;
          color: $color-black;
        }
      }
      h2 {
        border: 0;
        font-size: 20px;
        margin-bottom: 10px;
      }
      .btn-wrap {
        text-align: center;
        background: $color-black;
        margin-top: 10px;
        padding: 8px;
        .btn {
          width: 100%;
          font-weight: 400;
          padding: 0 3px;
          text-align: center;
          font-size: 11px;
          height: 12px;
          letter-spacing: 1px;
          display: inline-block;
          line-height: 12px;
          margin-top: 0;
          text-transform: uppercase;
          color: $color-white;
          font-family: $brandon-text-bold;
        }
        &:hover {
          background-color: $color-pink;
        }
      }
    }
    .bt-bottom-right {
      border-bottom-width: 0;
      position: fixed;
      border: 1px solid $color-light-gray;
      width: 100%;
      #{$rdirection}: 0;
      bottom: 0;
      @include breakpoint($medium-up) {
        width: 24.5em;
        #{$rdirection}: 10px;
        bottom: 10px;
      }
    }
    .bt-bottom-full {
      position: fixed;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      border-bottom-width: 0;
    }
  }
}
/* SETTINGS
 - make changes under the BRAND SPECIFICS section
---------------------------------------------- */
#bt_settings {
  .bt-content {
    border: 2px solid $color-dark-navy;
    padding: 15px;
    background-color: $color-white;
    z-index: 1001;
    font-size: 12px;
    line-height: 16px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    .bt-intro {
      h2 {
        text-transform: none;
        color: $color-dark-navy;
      }
      .bt-intro-copy {
        p {
          margin-top: 1em;
          margin-bottom: 1em;
        }
      }
    }
    /* TOGGLE SLIDER */
    .slide {
      margin: 30px auto;
      width: 264px;
      position: relative;
      overflow: hidden;
      @include breakpoint($medium-up) {
        width: 525px;
      }
    }
    @if $enable_cookie-img == true {
      .slide {
        width: 288px;
        @include breakpoint($medium-up) {
          width: 525px;
        }
      }
    }
    .slide-toggle {
      background: url('/media/export/images/cookies/m_all-off.png') 50% 100% no-repeat;
      width: 246px;
      background-size: 246px 147px;
      margin: 0;
      position: relative;
      overflow: hidden;
      @include breakpoint($medium-up) {
        background: transparent url('/media/export/images/cookies/all-off.png') 50% 100% no-repeat;
        width: 525px;
        background-size: contain;
      }
      li {
        cursor: pointer;
        float: $ldirection;
        list-style-type: none;
        width: 82px;
        height: 148px;
        @include breakpoint($medium-up) {
          width: 175px;
        }
        span {
          display: none;
          font-weight: bold;
          text-align: center;
          margin: 5px;
        }
      }
    }
    @if $enable_cookie-img == true {
      .slide-toggle {
        background: url('/media/export/images/cookies/m_all-off_v1.png') no-repeat;
        width: 288px;
        background-size: 288px 148px;
        @include breakpoint($medium-up) {
          background: url('/media/export/images/cookies/all-off_v1.png');
          width: 525px;
          background-size: contain;
        }
        li {
          width: 144px;
          @include breakpoint($medium-up) {
            width: 262px;
          }
        }
      }
    }
    .mask {
      position: absolute;
      overflow: hidden;
      top: 0;
      #{$ldirection}: 0;
      width: 0;
      border-#{$rdirection}: 1px solid $color-whisper-shade;
      transition: width 1s;
      -webkit-transition: width 1s; /* Safari */
      .slide-toggle {
        background-image: url('/media/export/images/cookies/m_all-on.png');
        margin: 0;
        @include breakpoint($medium-up) {
          background-image: url('/media/export/images/cookies/all-on.png');
          margin: 0;
        }
      }
      @if $enable_cookie-img == true {
        .slide-toggle {
          background: url('/media/export/images/cookies/m_all-on_v1.png') no-repeat;
          background-size: 288px 148px;
          @include breakpoint($medium-up) {
            background-image: url('/media/export/images/cookies/all-on_v1.png');
            background-size: contain;
            margin: 0;
          }
        }
      }
      .drag {
        position: absolute;
        bottom: 12px;
        width: 26px;
        height: 11px;
        background: transparent url('/media/export/images/cookies/icon_arrow.png') 50% 100% no-repeat;
        #{$rdirection}: 30px;
        @include breakpoint($medium-up) {
          #{$rdirection}: 70px;
        }
      }
      @if $enable_cookie-img == true {
        .drag {
          #{$rdirection}: 55px;
          @include breakpoint($medium-up) {
            #{$rdirection}: 100px;
          }
        }
      }
    }
    &.bt-content-desktop {
      padding: 2em;
      height: 100%;
    }
    /* PRIVACY INFORMATION */
    .bt-privacy-info {
      display: none !important;
      overflow: hidden;
      &.selected {
        display: block !important;
        &[data-userprivacy='OPTED_IN'] {
          .bt-privacy-willnot {
            display: none !important;
          }
        }
      }
      h3 {
        @include swap_direction(padding, 1em 1em 1em 40px);
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: $color-alice-blue-shade;
      }
      .bt-privacy-will {
        h3 {
          background-image: url('/media/export/images/cookies/icon_safe_lock.png');
        }
        li {
          background-image: url('/media/export/images/cookies/icon_safe_thick.png');
        }
      }
      .bt-privacy-willnot {
        h3 {
          background-image: url('/media/export/images/cookies/icon_unsafe_lock.png');
        }
        li {
          background-image: url('/media/export/images/cookies/icon_unsafe_thick.png');
        }
      }
      div {
        margin-#{$rdirection}: 2%;
        width: 100%;
        float: none;
        @include breakpoint($medium-up) {
          width: 48%;
          float: $ldirection;
        }
        ul {
          list-style-type: none;
          padding-#{$ldirection}: 0;
          margin: 1em;
          li {
            margin: 0.8em 0;
            padding-#{$ldirection}: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}

.customer-service-section {
  #bt_settings {
    div {
      display: inline;
    }
  }
}
