$disabled-color: #cccccc;
$disabled-cursor: text;
$ipad-min-width: 768px;
$ipad-max-width: 1024px;

#shipping-info-panel,
#billing-info-panel {
  .address-container address {
    font-style: normal;
  }
}

.checkout .checkout__content {
  #viewcart-panel {
    @include viewcart-panel;
  }
  #bottom-viewcart-buttons {
    @include viewcart-panel;
  }
  .checkout-buttons-container input.disabled {
    @include disable-button();
  }
  h3.h_newsletter {
    display: none;
  }
}

#colorbox {
  .waitlist-iframe-wrapper {
    width: 100%;
    height: 95%;
  }
}

.tiny-waitlist-overlay h2 {
  margin: 30px 0 30px 0;
  text-align: left;
}

.tiny-waitlist-overlay .field {
  border-top: 1px solid gray;
  padding-top: 35px;
}

.tiny-waitlist-overlay .email_input {
  display: inline-block;
}

.tiny-waitlist-overlay .email_input input[type="text"] {
  width: 250px;
  margin-bottom: 10px;
}

.tiny-waitlist-overlay .field .align-r {
  display: inline-block;
  margin-left: 20px;
}

.waitlist_thankyou_message {
  font-family: "Brandon Text Bold";
  margin: 130px 60px 90px 115px;
  line-height: 1.45em;
}

.billing {
  #checkout_complete .payment_method_container {
    .direct-debit .direct-debit-options a {
      border: 1px solid;
    }
  }
}

#billing-address-info-panel {
  #billing_address_form_container {
    border: 1px solid #000;
    padding: 20px;
    margin-top: 10px;
    .title_container {
      margin-bottom: 10px;
    }
  }
}

#recommended-products-panel {
  @media only screen and (max-device-width: $ipad-min-width) {
    .recommended-products-panel__content {
      padding: 15px;
      .recommended-product-items .recommended-item {
        .add_button input {
          font-size: 11px;
        }
      }
    }
  }
  @media only screen and (max-device-width: $ipad-max-width) {
    .recommended-products-panel__content {
      padding: 15px;
    }
  }
}

@media only screen and (max-device-width: $ipad-max-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      a.continue-shopping {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-device-width: $ipad-min-width) {
  #viewcart-panel {
    .viewcart-buttons-panel {
      .continue-buttons {
        float: none;
        margin-bottom: 10px;
        a {
          width: 100%;
        }
      }
      a.continue-shopping {
        width: 100%;
      }
    }
  }
}