$enable_cookie-img: true !default;

.section-store-locator.device-pc {
  #store-search-controls {
    input.search {
      width: 250px;
    }
  }
  .store-locator__main {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.section-customer-service-contact-us {
  .customer-service-section {
    .customer-service-contact-form__category {
      letter-spacing: 1px;
      padding: 1em 5px;
    }
    .customer-service-contact-form__category#category-products {
      letter-spacing: 0px;
      padding: 25px 2px 0px 2px;
    }
    .customer-service-contact-form__section {
      #category-online-content {
        .customer-service-contact-form__radio-item label::before {
          font-size: 13px;
          margin-top: 1px;
        }
        .customer-service-contact-form__radio-item label {
          .orderstatus_exchanges_radiobutton {
            display: inline-block;
            margin-top: 1px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}

.telluride-collection {
  .sheer-lip-color,
  .shimmer-brick {
    height: 617px;
  }
  .shimmer-brick .product-collection-product__description {
    margin-bottom: 45px;
  }
}

.gtl__steps__content {
  .gtl__steps__body p {
    width: 90%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service-contact-us {
    .customer-service-section {
      .customer-service-contact-form__category {
        letter-spacing: 0px;
        padding: 1em 5px;
        word-wrap: break-word;
      }
      .customer-service-contact-form__category#category-products {
        letter-spacing: 0px;
        padding: 1em 5px;
      }
    }
  }
  .customer-service-menu {
    a.menu__link--lvl-1 {
      width: auto;
      margin-right: 5px;
      padding-right: 5px;
      word-wrap: break-word;
    }
  }
  .telluride-collection {
    .lw-gel,
    .text-block {
      height: 430px;
    }
    .smokey-eye {
      .product-collection-product {
        bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section-store-locator {
    .store-locator__form-container {
      #store-search-controls {
        display: block;
      }
    }
  }
}

.checkout {
  .checkout__sidebar {
    section {
      margin-bottom: 10px;
      background: #eff0f0;
      padding: 16px 20px;
      p {
        margin-bottom: 15px;
      }
    }
    .account-utilities__section {
      h3.account-utilities__header {
        font-family: 'Brandon Text Bold';
        margin: 4px 0 10px 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #000000;
        text-transform: uppercase;
      }
    }
    #links-panel {
      background-color: #fff;
      border: 4px solid #eff0f0;
      ul.links_list {
        li {
          border: 0px;
          margin-bottom: 10px;
        }
      }
    }
    #offer-code-panel {
      .offer-code-panel__content {
        margin-top: 0px;
        #offer_code .offer_code_form_container {
          #one-offer-only {
            display: block;
            margin: 10px 0px;
          }
        }
      }
    }
    .return-link-container {
      margin-bottom: 10px;
    }
  }
  .checkout__content {
    #viewcart-panel {
      .viewcart-panel__content {
        margin-top: 10px;
        .total_column.sub__total {
          text-align: right;
          margin: 10px 0px;
        }
      }
      h2.viewcart-panel__title {
        border-bottom: 1px solid;
      }
      div.messages {
        margin: 10px 0px;
        ul li {
          margin-left: 0px;
        }
      }
    }
    #shipping-panel {
      .transaction-panel-details {
        margin-top: 20px;
        .transaction-item {
          float: left;
          margin: 0 28px 28px 0;
          position: relative;
          width: 275px;
          word-wrap: break-word;
        }
      }
    }
    #review-instructions {
      padding: 15px 0px;
      p {
        margin: 0px;
      }
    }
  }
  .below-columns {
    #recommended-products-panel {
      .recommended-products-panel__content .recommended-product-items .recommended-item {
        .description {
          margin: 10px 0px;
          .product_name {
            min-height: inherit;
          }
          div {
            margin: 0px 0px 10px 0px;
          }
          .color .swatch {
            margin-right: 10px;
          }
        }
      }
    }
  }
  ol.checkout-progress li {
    width: 175px;
    float: left;
    margin: 24px 24px 0;
    list-style-type: decimal;
  }
  .checkout-buttons-container {
    text-align: right;
    margin-bottom: 30px;
  }
  #checkout_review .newsletter-info {
    p {
      margin: 0px;
    }
    .newsletter-info__item {
      margin-left: 0px;
      padding-left: 0px;
    }
  }
}

#samples-panel #checkout_samples {
  .samples-buttons.bottom {
    display: block;
  }
  .samples {
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: #000000;
      }
    }
  }
}

.shipping {
  .checkout-progress li.checkout-progress__shipping {
    font-weight: bold;
  }
  .checkout__content {
    #shipping-page-info-panel #checkout_shipping {
      section {
        margin-bottom: 10px;
      }
      #shipping-address-info-panel {
        #address_form_container .address {
          .address-form__content {
            .title_container {
              margin-bottom: 10px;
            }
          }
        }
        #shipping-info-panel {
          padding: 0px;
          .shipping-address {
            margin-bottom: 10px;
          }
          ul.address_controls li {
            margin-bottom: 10px;
            &#choose-address select {
              margin-top: 5px;
            }
          }
        }
        h2.shipping-info-panel__title {
          padding-top: 15px;
        }
      }
      #billing-address-info-panel #billing-info-panel {
        padding: 0px;
        .billing-address {
          margin-bottom: 10px;
        }
      }
      #delivery-info-panel #delivery-info-content-panel {
        padding: 0px;
        #delivery-options {
          p {
            margin-bottom: 10px;
          }
        }
      }
      #emailpromo-info-panel .newsletter-info {
        padding: 0px;
        h3.newsletter-info__header {
          text-indent: 0px;
        }
        p {
          margin-left: 0px;
        }
        .newsletter-info__item {
          margin-left: 0px;
          padding-left: 0px;
        }
      }
    }
  }
}

.review {
  .checkout-progress li.checkout-progress__review {
    font-weight: bold;
  }
  .checkout__content {
    #checkout_review {
      .promotions-container {
        h3.newsletter-info__header {
          text-indent: 0px;
        }
      }
    }
  }
}

.billing {
  .checkout-progress li.checkout-progress__billing {
    font-weight: bold;
  }
  #checkout_complete {
    #payment-info-panel {
      .payment_method_container {
        margin-bottom: 15px;
        p {
          margin: 10px 0px;
        }
        div {
          margin-bottom: 10px;
          &.direct-debit .direct-debit-nickname input {
            margin-bottom: 0px;
          }
        }
        .payment-type {
          label {
            display: block;
            overflow: hidden;
            height: 60px;
            padding: 20px 0px;
            img {
              position: absolute;
              margin-top: -7px;
              left: 30px;
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_sofort_label {
              img {
                padding: 0px !important;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_debit_label {
              img {
                margin-top: -20px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_mc_label {
              img {
                margin-top: -20px;
              }
            }
            &#form--checkout_payment_type--field--PAYMENT_OPTION--index--payment_option_visa_label {
              img {
                width: 60px;
              }
            }
          }
        }
      }
    }
  }
  #promo-message {
    margin-bottom: 15px;
  }
}

.confirm {
  .checkout-progress li.checkout-progress__confirm {
    font-weight: bold;
  }
  .checkout__content {
    #confirm-panel {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.order-confirmation {
  .account-utilities {
    display: none;
  }
  .cart-item {
    margin-top: 20px;
  }
  .cart-item__thumb {
    width: 13%;
    margin-right: 10px;
  }
  .cart-item__desc {
    float: left;
    width: 30%;
    margin-left: 10px;
  }
  .cart-item__price {
    text-align: center;
  }
  .cart-item__qty {
    float: left;
    clear: none;
    padding-top: 0;
    margin: 0 10px;
    width: 14%;
    text-align: center;
  }
  .cart-item__total {
    width: 14%;
    float: left;
    line-height: 36px;
    text-align: right;
  }
  .order-totals {
    float: right;
    table {
      th,
      td {
        border: 0px;
      }
    }
  }
}

.order-details-page__content .order-totals {
  width: 40%;
  float: right;
  table {
    th {
      width: 80%;
    }
    td {
      width: 20%;
    }
  }
}

.section-store-locator {
  .store-locator__links-container {
    .store-locator__link-events {
      float: right;
      margin: 5px 0 0 20px;
    }
  }
}

.favorites-page__content {
  .favorites .my-lists__list-actions {
    .my-lists__list-link--share:before {
      top: 24px;
    }
  }
}

.samples-page {
  .product {
    min-height: 482px;
    width: 21.6%;
  }
}

#recommended-products-panel {
  .recommended-product-items .recommended-item {
    padding-bottom: 60px;
    .add_button {
      position: absolute;
      bottom: 0;
    }
    .column.item_info .product_subname {
      height: 20px;
    }
  }
}

.site-footer-contact {
  .site-footer-contact__item--phone {
    padding-left: 90px;
  }
  .site-footer-contact__item--chat {
    padding-left: 60px;
  }
}

.site-email-sms-signup__content {
  #site-email-sms-signup-error {
    margin: 27px 20px 10px;
  }
}

input[type='text']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.section-esearch {
  #perlgem-search-form {
    .search-form__submit {
      letter-spacing: 0.1em;
      margin-#{$ldirection}: 0;
    }
    .search-form__results-count {
      line-height: 2.5;
      #{$rdirection}: 181px;
    }
  }
  #search-wrapper {
    &.wrap {
      max-width: 1024px;
      @include breakpoint($large-up) {
        padding: 0 20px;
      }
    }
    #results-container {
      #col-1 {
        /* stylelint-disable-next-line declaration-no-important */
        display: none !important;
      }
      #col-2 {
        float: none;
        width: 100%;
        .products {
          .product-grid__content {
            max-width: none;
          }
          &#product-results {
            margin-#{$ldirection}: 0;
            .product-grid__content {
              max-width: inherit;
              display: flex;
              flex-wrap: wrap;
            }
            .product-grid__item {
              width: calc(100% / 3);
            }
          }
        }
      }
    }
  }
}

.site-utils__dropdown--account {
  .sign-in {
    .registration form input {
      width: 100%;
    }
    .registration__password-container {
      overflow: hidden;
    }
    .registration__show-pass {
      margin: 5px 0 0 0;
    }
    .registration__show-pass-text {
      letter-spacing: 0em;
    }
    .registration__terms {
      margin-top: 10px;
    }
    .registration__email-list {
      margin: 10px 0 18px 0;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .device-pc {
    .site-footer {
      .site-footer-contact {
        .site-footer-contact__link {
          margin-left: 8%;
        }
      }
      .site-email-signup {
        &__field {
          width: 53%;
        }
        &__submit {
          height: 32px;
          letter-spacing: 1px;
          width: 44%;
          padding: 0px;
        }
      }
    }
    .bobbis-lessons {
      &__footer {
        margin: 0;
      }
    }
    .makeup-lessons-item {
      &__wrap {
        padding: 30px 0 25px 0;
      }
    }
    .makeup-lessons-items {
      &__lessons {
        bottom: 5px;
        width: auto;
        .makeup-lessons-item {
          margin: 9px 9px 9px 0px;
        }
      }
    }
  }
  .site-nav {
    .menu__item--lvl-2 {
      padding-right: 25px;
      word-wrap: break-word;
    }
  }
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
  .site-footer {
    .site-email-signup {
      .site-email-signup__submit {
        height: 32px;
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .quickshop {
    .product__details .product__actions {
      .product__full-details-link-wrapper {
        float: none;
        margin-bottom: 10px;
      }
      .product__notify-me {
        margin-top: 10px;
      }
    }
  }
}

.site-header__addtobag {
  .site-header__addtobag-cell {
    .site-header__addtobag__product-info {
      div {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        div {
          font-size: 12px;
        }
      }
    }
    .site-header__addtobag-button {
      a {
        font-size: 13px;
      }
      @media only screen and (max-device-width: 1024px) {
        a {
          font-size: 12px;
        }
      }
    }
  }
}

.spp {
  .responsive-tabs--how_to_use-wrap {
    .resp-tab-item {
      letter-spacing: 2px;
      padding: 8px 10px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .section-customer-service {
    .customer-service-section {
      .customer-service-landing-block {
        height: 325px;
        .customer-service-landing-block__link {
          bottom: 15px;
        }
      }
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .spp {
    .responsive-tabs--how_to_use-wrap .resp-tab-item {
      padding: 8px 15px;
    }
  }
}

.site-footer .site-footer-contact ul {
  li.site-footer-contact__item {
    width: 50%;
    a {
      white-space: pre-wrap;
      span {
        margin-right: 10px;
      }
    }
  }
}

.ie8 {
  .section-store-locator {
    .store-locator--desktop {
      .store-locator__locate.geo_search,
      .store-locator-either-or {
        display: none !important;
      }
    }
  }
  .sign-in {
    .account-links .account-links__container {
      li {
        height: auto !important;
      }
    }
  }
  .makeup-lessons-item__thumb-image {
    max-width: none;
  }
}

.customer-service-menu {
  a.menu__link--lvl-1 {
    height: auto;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .past-purchases__content {
    .past-purchases-data-header__item,
    .past-purchases-data-item__item {
      letter-spacing: 0;
    }
    .past-purchases-data-header__item.created-date,
    .past-purchases-data-item__item.created-date {
      width: 20%;
    }
    .past-purchases-data-header__item.trans-id,
    .past-purchases-data-item__item.trans-id {
      width: 31%;
    }
  }
}

#samples-panel {
  .samples-buttons.bottom {
    display: block;
  }
}

.ie8 {
  .sign-in .registration__terms-text {
    padding-left: 0px;
  }
}

.offers-details {
  .offers-details-item__button {
    margin-left: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .offers-hero {
    .offers-hero__button {
      margin: 10px 0 0 0px;
    }
  }
  .offers-details {
    .offers-details-item__button {
      margin: 13px 0 0 25px;
    }
  }
  .offers-hero__container {
    .offers-hero__description {
      .offers-hero__text {
        min-height: 110px !important;
      }
      .offers-hero__actions {
        a {
          letter-spacing: 1px;
        }
      }
    }
  }
}

.offers-hero__container {
  .offers-hero__description {
    .offers-hero__text {
      min-height: 110px !important;
    }
    .offers-hero__actions {
      a.offers-hero__link {
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        padding: 0px 0px 5px 0px;
      }
    }
  }
}

.search-form {
  .product__price {
    width: 115px;
  }
  .product__image-wrapper {
    .product__image {
      width: auto;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .makeup-lessons__header--content .makeup-lessons__header--link {
    padding: 5px 2px;
  }
  a.makeup-lessons__header {
    background-size: 650px 424px;
  }
  div.makeup-lessons__header {
    background-size: 480px 328px;
  }
}

@media only screen and (max-device-width: 768px) {
  .site-footer .site-footer-contact ul {
    li.site-footer-contact__item--phone {
      float: none;
      width: 100%;
      text-align: center;
      padding-left: 0px;
    }
    li.site-footer-contact__item--email {
      width: 100%;
      text-align: center;
      padding-left: 0px;
    }
  }
}

.js-popup-offer-overlay-cbox {
  .popup-offer.js-popup-offer {
    overflow: hidden;
    .popup-offer__outer-wrap.popup-offer__sign-up {
      padding: 15px 15px 0 15px;
      h2.popup-offer__heading {
        margin-bottom: 10px;
      }
      .popup-offer__body {
        p {
          margin: 0 0 10px 0;
        }
      }
    }
    .popup-offer__outer-wrap.js-popup-offer-signed-up {
      padding: 15px 15px 0 15px;
    }
  }
}

.bobbi-glow {
  .product_highlighting-powder {
    min-height: 345px;
    background-position: center 20px;
    padding: 350px 30px 30px 0;
    border-top: 1px solid #eff0f0;
    border-bottom: 1px solid #eff0f0;
  }
}

.site-email-sms-thank-you__header {
  h1 {
    height: 80px;
  }
}

.site-email-sms-signup {
  .site-email-sms-signup__header {
    h1 {
      font-size: 2em;
      letter-spacing: 3px;
    }
    h2 {
      font-size: 1.4em;
      letter-spacing: 0;
    }
  }
  .site-email-sms-signup__agree {
    span.label-content {
      letter-spacing: 0.9px;
    }
    input#form--email_signup--field--SMSPROMO_SMS_PROMOTIONS,
    input#form--email_signup--field--PC_EMAIL_PROMOTIONS {
      position: absolute;
    }
  }
}

.node-elc-mpp {
  .page-header {
    background-color: #ffffff !important;
  }
  .page-header__title__inner {
    background-color: #ffffff !important;
  }
}

.offer-code-panel {
  .offer_code_form_container {
    em#one-offer-only {
      span {
        a {
          margin-top: 30px;
          font-style: normal;
        }
      }
    }
  }
}

#cboxContent {
  .offers-everyday {
    display: none;
  }
  .offers-hero {
    .offers-hero__container {
      .offers-hero__actions {
        a.offers-hero__button.btn {
          display: none;
        }
      }
    }
    .align-center {
      h3.offers-hero__header {
        border-bottom: 0px;
        &::before {
          top: 23%;
        }
      }
    }
  }
}

#node-1734 {
  .foundation-finder {
    &__tips {
      width: 100%;
    }
    &__chat {
      display: none;
    }
  }
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    font-family: 'Brandon Text';
    font-size: 32px;
    line-height: 1.2em;
  }
}

div.thanks_message {
  width: 450px;
  margin: 8px 0 0 18px;
}

div.thanks_header {
  font-family: 'Brandon Text';
  font-size: 32px;
  line-height: 1.2em;
  margin: 40px 0 0 18px;
}

.skin__foundation {
  &__menu--02 {
    .skin__foundation__menu__link {
      span {
        &.icon-livechat {
          display: none;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  .waitlist_message {
    margin: 0 0 17px 0;
    font-size: 19px;
  }
}
/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-helpful {
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'Ja ';
              visibility: visible;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'Nein ';
              visibility: visible;
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  #pr-write {
    #pr-war-form {
      .form-group {
        .input-add-tag {
          width: 84%;
        }
      }
      .pr-hautbedürfnisse-form-group,
      .pr-produktvorteile-form-group {
        .pr-control-label {
          float: left !important;
          margin-right: 20px;
        }
      }
      .pr-describeyourself-form-group {
        display: none;
      }
    }
  }
}

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      a.pr-snippet-write-review-link {
        text-transform: initial;
      }
    }
  }
}

.account-page,
.past-purchases-page,
.favorites-page,
.address-book-page,
.profile-page {
  .account-utilities {
    width: auto;
  }
  .profile-pic-main {
    #profile-pic-name {
      width: 87%;
    }
  }
}

.account-page.account__section {
  section.account-page__content {
    section.account-address {
      h3.section-header__header {
        width: 231px;
      }
    }
  }
}

.account-payment,
.account-loyalty {
  .section-header {
    padding: 0 40px;
  }
}

.account-utilities__section {
  .account-utilities__customer-service {
    text-align: left;
  }
}

.popup-offer__footer p {
  margin-bottom: 12px;
}

#profile_preferences {
  .optional-info__header {
    padding-left: 20px;
  }
  .newsletter-info__text--new {
    margin-top: -25px;
  }
}

#checkout_review {
  .newsletter-info {
    .newsletter-info__item {
      margin-top: -15px;
    }
  }
}

#confirm {
  .confirmation-panel {
    .legal_requirements_header {
      display: none;
    }
  }
}

#checkout_shipping {
  .newsletter-info__header {
    margin-top: 15px;
    font-size: 14px;
    margin-left: -17px;
  }
  .newsletter-info__item {
    margin-top: -25px;
    margin-left: 0px;
    padding-left: 0px;
  }
}

#shipping {
  section.pg_wrapper.shipping.viewcart.checkout {
    #address-section-container {
      padding-top: 5px;
      #billing-address-info-panel {
        padding-top: 7px;
        padding-bottom: 8px;
      }
    }
  }
  #cboxLoadedContent {
    #address_form_container {
      .address-overlay-shipping {
        .address-form__content {
          span.required_mark.mandatory {
            display: none;
          }
        }
      }
    }
  }
}

#payment {
  .error_messages.error li {
    margin-left: 0px;
  }
}

.lp_radio_button,
.lp_checkbox_button {
  input {
    display: none;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

#lpChat {
  .lp_actions_bar_container {
    .lp_actions_bar {
      .lp_action_item {
        .lp_action_wrapper {
          .lp_title {
            text-transform: none;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .optional-info__item {
    float: left;
  }
}

.profile-info__password {
  padding-top: 15px;
}

section.newsletter-info {
  a.info-link {
    margin-left: 20px;
  }
}

.site-header {
  .site-utils__item--email {
    .site-email-signup {
      &__field {
        text-align: center;
      }
    }
  }
}

.checkout-co-enabled {
  .pg_wrapper {
    .checkout {
      &__sidebar {
        #links-panel {
          background-color: transparent;
        }
      }
    }
  }
}
